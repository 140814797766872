<template>
  <div class="publishing-tools">
    <!-- Mode select -->
    <mode-select
      :items="items"
      @input="setMode"
    />
    <!-- Desktop Actions -->
    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-btn
        v-for="action in actions"
        :key="action.label"
        :color="action.color"
        v-bind="btnDefaultProps"
        @click="handleAction(action)"
      >
        <!-- Label -->
        {{ !!$vuetify.breakpoint.lgAndUp ? $t(action.label) : null }}
        <!-- Icon -->
        <v-icon
          :right="!!$vuetify.breakpoint.lgAndUp"
          v-html="action.icon"
        />
      </v-btn>
    </template>
    <!-- Mobile actions dropdown -->
    <v-menu v-else>
      <!-- "More actions" (3 dots) menu toggle -->
      <template v-slot:activator="{ on }">
        <v-btn
          v-bind="btnDefaultProps"
          color="grey lighten-2"
          v-on="on"
        >
          <v-icon v-text="'more_vert'" />
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="action in actions"
          :key="action.label"
          @click="handleAction(action)"
        >
          <!-- List icon -->
          <v-list-item-icon class="mr-2">
            <v-icon
              small
              v-text="action.icon"
            />
          </v-list-item-icon>
          <!-- List content -->
          <v-list-item-content>
            <v-list-item-title v-t="action.label" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import ModeSelect from './ModeSelect/ModeSelect';
import buttonsMixin from '../../mixins/buttons';

export default {
  name: 'PublishingTools',
  components: {
    ModeSelect,
  },
  mixins: [buttonsMixin],
  data: () => ({
    // 3 possible modes: live, draft, archived
    mode: null,
    items: [
      {
        mode: 'draft',
        version: 3,
        date_created: '07/07/20',
      },
      {
        mode: 'live',
        version: 2,
        date_created: '08/08/20',
      },
      {
        mode: 'archived',
        version: 1,
        date_created: '06/06/20',
      },
    ],
  }),
  computed: {
    /**
     * Actions change according to current mode. Has to be reactive in
     * computed property because of mode's changing nature
     *
     * @returns {Array}
     */
    actions () {
      return [
        {
          label: 'ui.preview',
          icon: 'remove_red_eye',
          color: 'grey lighten-2',
        },
        {
          label: `${this.mode === 'live' ? 'ui.unpublish' : 'ui.delete' }`,
          icon: `${this.mode === 'live' ? 'undo' : 'delete' }`,
          color: 'grey lighten-2',
        },
        {
          label: `${this.mode === 'live' ? 'ui.new_draft' : 'ui.publish' }`,
          icon: `${this.mode === 'live' ? 'file_copy' : 'publish' }`,
          color: 'primary',
        },
      ];
    },
  },
  methods: {
    /**
     * Sets the current mode (live, draft, archived) according to
     * the select dropdown menu item
     *
     * @param {Object} item - The current selected item
     */
    setMode (item) {
      this.mode = item.mode;
    },
    /**
     * Handle action (TBD)
     *
     * @param (TBD)
     * @returns (TBD)
     */
    handleAction (action) {
      // TBD
    },
  },
};
</script>

<style lang="scss" scoped>
// General
.publishing-tools {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // Button overrides
  .v-btn {
    @include rem(margin-left, 8px);
  }
}
</style>
