<template>
  <status-field
    :value="status"
    :items="items"
    :disabled="isDisabled"
    @input="handleInput"
  />
</template>

<script>
import queriesMixin from '../../mixins/queries';
import StatusField from '../FieldComponent/StatusField/StatusField';

export default {
  name: 'Status',
  components: {
    StatusField
  },
  mixins: [
    queriesMixin,
  ],
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    field: {
      type: Object,
      required: false,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    typeName: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      status: null,
      isDisabled: false,
      valuesItems: [],
    };
  },
  computed: {
    items () {
      let value;
      if (this.valuesItems.length > 0) {
        value = this.valuesItems;
      } else if (this.field !== null && this.field.items !== undefined) {
        value = this.field.items;
      }
      return value;
    },
    currentModule () {
      return this.$xms.config.modules.crud.types.find(type => type.id === this.typeName);
    },
  },
  watch: {
    value (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.status = newValue;
      }
    },
  },
  async created () {
    this.status = this.value;
    this.isDisabled = this.disabled;
    if (this.typeName !== null) {
      const currentModule = this.currentModule;
      if (currentModule !== null && currentModule !== undefined && currentModule.fields !== undefined) {
        const statusField = currentModule.fields.find(field => field.key === currentModule.itemStatus);
        if (statusField !== undefined) {
          this.isDisabled = statusField.props && statusField.props.disabled;
          if (statusField.items !== undefined) {
            this.valuesItems = statusField.items;
          }
        }
      }
    };
  },
  methods: {
    async handleInput (value) {
      if (this.status !== value) {
        this.status = value;
        if (this.typeName !== null && this.itemId !== null) {
          const idSingular = this.getIdSingularFromTypeName(this.typeName);
          const mutationPatch = this.getPatchMutationName(idSingular);
          const formData = { status: value };
          try {
            await this.executePatchMutation({
              mutationName: mutationPatch,
              targetId: this.itemId,
              fields: [{ key: this.currentModule.itemStatus, type: 'status' }]
            }, formData);

            this.$reportSuccess({ message: this.$t('dialogs.update_success') });
          } catch (error) {
            this.$reportError({ message: `${error.message}` });
          }
        }
        this.$ga.event({
          eventCategory: 'crud',
          eventAction: 'ui/button/status',
          eventLabel: `module: ${this.typeName}`,
        });
        this.$emit('change', value);
      }
    },
    getIdSingularFromTypeName (typeName) {
      let result = null;
      const currentModule = this.currentModule;
      if (currentModule !== undefined) {
        result = currentModule.idSingular;
      }
      return result;
    },
  },
};
</script>
