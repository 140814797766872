<template>
  <v-list
    class="py-0"
    dense
  >
    <template v-for="group in groupFields">
      <!-- Group with children -->
      <!-- Expand or collapse if child is selected or not -->
      <v-list-group
        v-if="Array.isArray(group.children)"
        :key="group.key"
        :value="group.children.some(childGroup => childGroup.key === groupNavKey)"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ $t(group.label) }}</v-list-item-title>
        </template>

        <!-- Children -->
        <v-list-item
          v-for="childGroup in group.children.filter(childGroup => childGroup.panel)"
          :key="childGroup.key"
          :class="{
            'no-border': contextualDrawerIsMini === true,
          }"
          :to="`#${$t(childGroup.hashLink)}`"
          link
          @click.stop="handleListItemClick(childGroup.key)"
        >
          <v-list-item-icon
            v-if="contextualDrawerIsMini === true"
            class="mr-1"
          >
            <v-icon
              small
              v-text="'circle'"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-t="childGroup.label" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- Group without children -->
      <v-list-item
        v-else-if="group.panel"
        :key="group.key"
        :class="{
          'no-border': contextualDrawerIsMini === true,
        }"
        :to="`#${$t(group.hashLink)}`"
        link
        @click.stop="handleListItemClick(group.key)"
      >
        <v-list-item-icon
          v-if="contextualDrawerIsMini === true"
          class="mr-1"
        >
          <v-icon
            small
            v-text="'circle'"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-t="group.label" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import typeConfigMixin from '../../mixins/typeConfig.js';

/**
 * Navigation Group list of all field groups
 * Updates based on groupNavKey state and current route
 */
export default {
  name: 'GroupNav',
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.$route.meta.type;
      }
    }),
  ],
  computed: {
    ...mapState('crud', ['groupNavKey']),
    ...mapState('crud', ['currentModule']),
    ...mapState('ui', ['contextualDrawerIsMini']),
  },
  methods: {
    ...mapActions('crud', ['setGroupNavKey']),
    handleListItemClick (key) {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/drawer/contextual/anchor',
        eventLabel: `module: ${this.currentModule} - anchor: ${key}`,
      });
      this.setGroupNavKey({ key });
    },
  }
};
</script>

<style lang="scss" scoped>
// Active item
::v-deep .v-list-item--active {
  color: var(--color-primary);

  &.no-border {
    &::after {
      border: none;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-width: 3px;
    border-style: solid;
    border-color: var(--color-primary);
  }

  &.v-list-group__header::after {
    display: none;
  }
}

// Open/collapse
::v-deep .v-list-group__header__append-icon .v-icon {
  transform: rotate(-90deg) !important;

  .v-list-group--active & {
    transform: rotate(0deg) !important;
  }
}
</style>
