export default {
  computed: {
    /**
     * Buttons props are in computed property because they have
     * to be reactive according to breakpoints
     *
     * @returns {Object}
     */
    btnDefaultProps () {
      return {
        'x-small': !!this.$vuetify.breakpoint.mdAndDown,
        small: !!this.$vuetify.breakpoint.lgAndUp,
        fab: !!this.$vuetify.breakpoint.mdAndDown,
        width: !!this.$vuetify.breakpoint.mdAndDown ? 28 : null,
        height: !!this.$vuetify.breakpoint.mdAndDown ? 28 : null,
        depressed: true,
      };
    },
  },
};
