<template>
  <div
    class="preview-btn"
  >
    <v-btn
      v-bind="btnDefaultProps"
      :href="previewUrl"
      :disabled="isDisabled"
      color="grey lighten-2"
      target="_blank"
      class="ml-2"
    >
      <!-- Label -->
      {{ !!$vuetify.breakpoint.lgAndUp ? $t('ui.preview') : null }}
      <!-- Icon -->
      <v-icon
        :right="!!$vuetify.breakpoint.lgAndUp"
        v-html="'remove_red_eye'"
      />
    </v-btn>
  </div>
</template>

<script>
import axios from 'axios';

import buttonsMixin from '../../mixins/buttons';

export default {
  name: 'PreviewButton',
  mixins: [buttonsMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
    preview: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data: () => ({
    isDisabled: true,
  }),
  computed: {
    previewUrl () {
      try {
        return this.preview(this.item, this.$t.bind(this));
      } catch (e) {
        return '';
      }
    },
  },
  watch: {
    item () {
      this.isValidLink();
    },
  },
  async mounted () {
    await this.isValidLink();
  },
  methods: {
    async isValidLink () {
      try {
        this.$i18n.locale = this.$store.state.content.contentLang;
        await axios.get(
          this.previewUrl,
          {
            auth: {
              username: 'dev',
              password: 'dev123'
            },
          }
        );
        this.isDisabled = false;
      } catch(e) {
        console.error(e);
        this.isDisabled = true;
      }
    },
  }
};
</script>
