<template>
  <div>
    <portal to="content-bar-left">
      <v-btn
        v-if="hasTreeview"
        :height="36"
        :width="36"
        :color="treeviewHidden ? 'normal' : 'primary'"
        icon
        class="mr-2"
        @click="toggleTreeview"
      >
        <v-icon>
          account_tree
        </v-icon>
      </v-btn>
    </portal>
    <portal to="content-bar-center">
      <div class="content-bar-center__items">
        <!-- Duplicate button -->
        <v-btn
          v-if="canDuplicate"
          v-bind="btnDefaultProps"
          :loading="isDuplicating"
          color="grey lighten-2"
          target="_blank"
          class="ml-2"
          @click="duplicateItem"
        >
          <!-- Label -->
          {{ !!$vuetify.breakpoint.lgAndUp ? $t('ui.duplicate') : null }}
          <!-- Icon -->
          <v-icon
            :right="!!$vuetify.breakpoint.lgAndUp"
            v-html="'content_copy'"
          />
        </v-btn>
        <publishing-tools v-if="showPublishingTools" />
        <preview-button
          v-if="showPreviewButton"
          :preview="typeConfig.previewUrl"
          :item="data"
        />
      </div>
    </portal>
    <portal to="content-bar-right">
      <!-- Status -->
      <div class="d-inline-block ml-2">
        <status
          v-if="!$apolloData.queries.data.loading && statusField && status !== null"
          :value="getStatus"
          :disabled="statusField.props && statusField.props.disabled"
          :type-name="typeName"
          @change="handleStatus"
        />
      </div>
      <!-- Save -->
      <!-- Tooltip: Tells the number of errors -->
      <v-tooltip
        :disabled="formEditErrorsCount === 0"
        bottom
      >
        <template v-slot:activator="{ on }">
          <!-- Had to wrap v-on activator in a span because we could not show the tooltip on a disabled button -->
          <span v-on="on">
            <!-- Badge (notification) -->
            <v-badge
              :content="formEditErrorsCount"
              :value="formEditErrorsCount > 0"
              color="error"
              class="ml-2"
              bordered
              overlap
              small
            >
              <!-- Save button -->
              <v-btn
                :x-small="!!$vuetify.breakpoint.smAndDown"
                :small="!!$vuetify.breakpoint.mdAndUp"
                :fab="!!$vuetify.breakpoint.smAndDown"
                :width="!!$vuetify.breakpoint.smAndDown ? 28 : null"
                :height="!!$vuetify.breakpoint.smAndDown ? 28 : null"
                :disabled="disableSaveButton"
                :loading="loadingSave"
                depressed
                color="primary"
                @click.native="handleSave"
              >
                <template v-if="!!$vuetify.breakpoint.mdAndUp">
                  {{ $t('ui.save') }}
                </template>
                <v-icon v-else>
                  save
                </v-icon>
              </v-btn>
            </v-badge>
          </span>
        </template>
        <!-- Tooltip content -->
        <span>
          {{ `${$t('errors.form_contains')} ${formEditErrorsCount}` }}
          {{ formEditErrorsCount > 1 ? $t('errors.errors') : $t('errors.error') }}.
        </span>
      </v-tooltip>
    </portal>
    <portal
      v-if="groupFields != null"
      to="contextual-drawer"
    >
      <group-nav />
    </portal>
    <v-row align="stretch">
      <v-col
        v-if="hasTreeview && treeviewHidden === false"
        class="treeview-column col-12 col-md-4 col-lg-3"
      >
        <div
          ref="treeviewWrapper"
          class="treeview-wrapper elevation-1"
        >
          <tree-table
            v-if="hasTreeview && treeData && treeData.length /* Ensures nodes are open once mounted */"
            ref="treeview"
            :items="treeData"
            :item-children="typeName"
            :active="[editId]"
            :open.sync="openTreeNodes"
            clickable-rows
            @click:row="goToEdit(typeConfig.id, $event.id, typeName, true)"
            @hook:mounted="updateTreeviewScrollPosition"
          />
        </div>
      </v-col>
      <v-col :class="['col-12', { 'col-md-8 col-lg-9': hasTreeview && treeviewHidden === false }]">
        <v-form
          ref="form-edit"
          v-model="formEditIsValid"
          @input="handleFormBoundModel"
        >
          <!-- Content lang warning message -->
          <v-alert
            v-if="showContentLangWarning === true"
            type="warning"
            border="left"
            class="mb-3"
            outlined
            prominent
            dense
          >
            {{ $t('warnings.content_lang') }}
          </v-alert>
          <v-expansion-panels
            v-if="!$apolloData.queries.data.loading && data"
            :value="openedPanels"
            multiple
          >
            <v-expansion-panel
              v-for="group in groupPanels"
              :id="$t(group.hashLink)"
              ref="panels"
              :key="group.key"
              :readonly="groupFields == null"
            >
              <v-expansion-panel-header :hide-actions="groupFields == null">
                {{ $t(group.label) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <type-fields
                  :id="editId"
                  :fields="editGroupFields(groupFields == null ? undefined : group.key)"
                  :data="workingData"
                  :type-name="typeName"
                  @update:data="handleUpdateData"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            v-else
            class="d-flex justify-center py-10"
          >
            <BaseSpinner />
          </div>
          <div class="text-center mt-8 mb-5">
            <v-btn
              :loading="loadingSave"
              :disabled="disableSaveButton"
              color="primary"
              @click.native="handleSave"
            >
              {{ $t('ui.save') }}
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
    <custom-dialog
      v-model="customDialogOpen"
      :title="customDialogTitle"
      :subtitle="customDialogSubtitle"
      :show-action="customDialogShowAction"
      @close="customDialogCloseAction"
      @confirm="customDialogAction"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { get, cloneDeep } from 'lodash';
import queriesMixin from '../../mixins/queries';
import typeConfigMixin from '../../mixins/typeConfig';
import routingMixin from '../../mixins/routing';
import buttonsMixin from '../../mixins/buttons';
import { smoothScrollTo } from '../../helpers/util';

import GroupNav from '../../components/GroupNav/GroupNav';
import CustomDialog from '../../components/Modales/CustomDialog';
import Status from '../../components/Status/Status';
import PublishingTools from '../../components/PublishingTools/PublishingTools';
import PreviewButton from '../../components/PreviewButton/PreviewButton';

const TreeTable = () => import('../../components/TreeTable/TreeTable');

export default {
  name: 'Edit',
  components: {
    CustomDialog,
    GroupNav,
    Status,
    TreeTable,
    PublishingTools,
    PreviewButton,
  },
  mixins: [
    typeConfigMixin({
      typeName () {
        return this.typeName;
      }
    }),
    queriesMixin,
    routingMixin,
    buttonsMixin,
  ],
  apollo: {
    data: {
      query () {
        return this.createSingleItemQuery({
          type: this.typeConfig.idSingular,
          fields: this.editFetchFields,
        });
      },
      result (data) {
        const { id, itemLocales, itemStatus, itemParents, itemParentsIds } = this.typeConfig;

        if (data.error || data.data.data === null) {
          this.$router.push({ name: `${id}-listing`});
          return;
        }

        const locales = data.data.data[itemLocales];

        this.status = data.data.data[itemStatus];

        // Update open tree nodes
        const parentIds = (
          data.data.data[itemParents] &&
          data.data.data[itemParents][0] &&
          data.data.data[itemParents][0][itemParentsIds]
        ) || [];

        this.openTreeNodes = [...parentIds];

        if (locales != null && locales) {
          this.locales = locales.split(',');
        }

        // When refetching, keep previous workingData as well as refetched info
        this.workingData = Object.assign(cloneDeep(data.data.data), this.formData, { id: this.editId });
        this.setEditData({ data: this.workingData });
      },
      variables () {
        return {
          id: this.editId,
        };
      },
      skip () {
        return !this.editId;
      },
    },
    treeData: {
      skip () {
        return !this.hasTreeview;
      },
      query () {
        return this.createTreeviewQuery({
          fields: this.listingFields,
          type: this.typeName,
          maxLevel: this.typeConfig.treeviewMaxLevel,
        });
      },
      update (data) {
        return data.rows;
      }
    }
  },
  data () {
    return {
      openedPanels: [],
      locales: [],
      workingData: {},
      formData: {},
      openTreeNodes: [],
      formEditErrorsCount: 0,
      formEditIsValid: false,
      fieldCol: 'col-12 col-sm-6 col-lg-4',
      loadingSave: false,
      isDuplicating: false,
      fieldsWithErrors: [],
      changes: false,
      status: null,
      hasConfirmExitWithoutSaving: false,
      customDialogOpen: false,
      customDialogTitle: '',
      customDialogSubtitle: '',
      customDialogAction: () => {},
      customDialogShowAction: true,
    };
  },
  computed: {
    ...mapState('crud', ['groupNavKey', 'formHasUnsavedChanges', 'displayUnsavedWarning']),
    ...mapState('content', ['contentLang', 'targetContentLang']),
    ...mapGetters('auth', ['isLoggedIn', 'canAddModule']),
    ...mapGetters('crud', ['getTypeInfos']),
    canDuplicate () {
      return this.typeConfig.duplicable
        && this.canAddModule(this.typeName)
        && this.$apolloData.queries.data.loading === false;
    },
    typeName () {
      return this.$route.meta.type;
    },
    editId () {
      return parseInt(this.$route.params.id);
    },
    getStatus () {
      return this.status;
    },
    statusField () {
      return this.getFieldDefinition(this.typeConfig.itemStatus);
    },
    currentContentLang () {
      return this.contentLang;
    },
    showContentLangWarning () {
      return this.locales.length > 0 && this.locales.includes(this.currentContentLang) === false;
    },
    showPublishingTools () {
      return this.typeConfig.publishingTools === true;
    },
    showPreviewButton () {
      return this.$apolloData.queries.data.loading === false
          && this.typeConfig.publishingTools !== true
          && this.typeConfig.previewUrl !== undefined;
    },
    /**
     * Evaluates hash of the current group panel
     * @returns {string|null} - The current hash. Null if not found
     */
    currentGroupHash () {
      const targetPanelConfig = this.groupPanels.find(group => group.key === this.groupNavKey);

      if (targetPanelConfig && targetPanelConfig.hashLink) {
        return `#${this.$t(targetPanelConfig.hashLink)}`;
      }

      return null;
    },
    disableSaveButton () {
      return this.loadingSave === true
        || this.typeConfig.saveable === false
        || this.formHasUnsavedChanges === false
        || this.formEditIsValid === false;
    },
    hasTreeview () {
      return this.typeConfig.treeview === true;
    },
    /**
     * Treeview hidden.
     * Setting this property toggles between table (true) and treeview (false)
     *
     * @returns {boolean}
     */
    treeviewHidden: {
      get () {
        const viewType = this.getTypeInfos(this.typeName, 'viewType');

        return viewType
          ? viewType !== 'treeview'
          : false; // Default state matches listing
      },
      set (treeviewHidden) {
        this.setTypeInfo({
          typeName: this.typeName,
          key: 'viewType',
          value: treeviewHidden ? 'table' : 'treeview',
        });
      }
    }
  },
  watch: {
    // Init type infos if type name changes while in view
    typeName: {
      immediate: true,
      handler (typeName) {
        this.initTypeInfos(typeName);
      },
    },
    data (data, oldData) {
      if (data) {
        const breadcrumb = get(data, this.typeConfig.itemBreadcrumb, data.title);
        this.$store.dispatch('crud/setBreadcrumb', breadcrumb);
      }
    },
    // Updates url hash everytime current group hash state changes
    currentGroupHash: {
      immediate: true, // Calls handler on created
      handler (hash, oldHash) {
        // On page refresh, the oldHash is undefined.
        // We must get the current hash from $router object to avoid navigationDuplicated error
        const currentHash = get(this.$router, 'currentRoute.hash', null);
        if (![oldHash, currentHash].includes(hash)) {
          this.$router.replace({ hash: hash || '#' });
        }
      },
    },
    // Check if user is attempting to change language, show display warning
    displayUnsavedWarning () {
      if (this.displayUnsavedWarning === true) {
        this.customDialogTitle = this.$t('dialogs.exit_without_saving_title');
        this.customDialogSubtitle = this.$t('dialogs.exit_without_saving_subtitle');
        this.customDialogAction = () => {
          this.updateContentLang({
            contentLang: this.targetContentLang,
            force: true,
          });
          this.setFormHasUnsavedChanges(false);
        };
        this.customDialogShowAction = true;
        this.customDialogOpen = true;
      }
    },
  },
  created () {
    this.scrollingToGroup = false;
    this.handlingPageScroll = false;
    // Open all expansion panels by default before the component is rendered
    // We create an array that is the length of our items with all values as true
    this.openedPanels = [...Array(this.groupPanels.length).keys()].map((index, panel) => panel);
    this.setFormHasUnsavedChanges(false);
  },
  beforeRouteUpdate (to, from, next) {
    if (
      to.name === from.name &&
      String(to.params.id) !== String(from.params.id)
    ) {
      this.handleRouteChange(to, from, next);
    } else {
      next();
    }
  },
  beforeRouteLeave (...args) {
    this.handleRouteChange(...args);
  },
  mounted () {
    // Automatically scroll to current group panel when state is updated
    this.unsubscribeFromActions = this.$store.subscribeAction((action) => {
      // Subscribe to store action
      // Allows to pass additional options without adding to state
      if (action.type === 'crud/setGroupNavKey') {
        // Prevent scroll when specified
        const { key, scroll = true } = action.payload;

        if (scroll) {
          this.scrollToGroup(key);
        }
      }
    });

    this.unsubscribeFromMutations = this.$store.subscribe(mutation => {
      if (mutation.type === 'content/startUpdateContentLang') {
        this.formData = {};
      }
    });

    // Create page scroll event
    // Bind it to instance for future removal
    this.windowListener = this.handlePageScroll.bind(this);
    this.windowListener();
    window.addEventListener('scroll', this.windowListener);
    this.setCurrentModule(this.typeName);
  },
  destroyed () {
    // Unsubscribe from mutations and actions
    this.unsubscribeFromActions();
    this.unsubscribeFromMutations();
    // Remove page scroll event
    window.removeEventListener('scroll', this.windowListener);
    // Reset crud breadcrumb
    this.$store.dispatch('crud/setBreadcrumb', '');
    this.setCurrentModule(null);
  },
  methods: {
    ...mapActions('crud', ['setFormHasUnsavedChanges', 'hideUnsavedWarning', 'initTypeInfos', 'setTypeInfo']),
    ...mapActions('crud', ['setCurrentModule', 'setEditData']),
    ...mapActions('content', ['updateContentLang']),
    toggleTreeview () {
      this.treeviewHidden = !this.treeviewHidden;
      this.$ga.event({
        eventCategory: 'crud',
        eventAction: 'ui/drawer/treeview/toggle-visibility',
        eventLabel: `module: ${this.typeName} - visible: ${!this.treeviewHidden}`,
      });
    },
    handleRouteChange (to, from, next) {
      /**
       * if -> User is logged in and the current module edit item has pending changes
       * else -> Nothing changed, we always allow next() to be applied to $route change
       */
      if (this.isLoggedIn === true && this.formHasUnsavedChanges === true) {
        /**
         * if -> The user changes the route without saving, giving him a warning prompt to confirm his action
         * else -> The user has confirmed, allowing next() to be applied to $route change in handleExitWithoutSaving()
         */
        if (this.hasConfirmExitWithoutSaving === false) {
          this.confirmExitWithoutSaving(to);
        } else {
          this.formData = {};
          this.hasConfirmExitWithoutSaving = false;
          this.setFormHasUnsavedChanges(false);
          next();
        }
      } else {
        this.setFormHasUnsavedChanges(false);
        next();
      }
    },
    handleFormBoundModel () {
      const form = this.$refs['form-edit'];

      if (form !== undefined) {
        this.formEditErrorsCount = Object.values(form.errorBag).filter(error => error === true).length;
      }
    },
    getGroupFieldChildren (groupField) {
      return Array.isArray(groupField.children)
        ? groupField.children
        : [groupField];
    },
    editGroupFields (key) {
      if (key) {
        return this.editFields.filter(field => field.groupkey === key);
      }

      return this.editFields;
    },
    /**
     * Scroll event handler
     * Checks window position on scroll and updates groupNavKey state accordingly
     */
    handlePageScroll () {
      // Ignores handler when function has already been called for current frame
      // or when page is currently scrolling to a group
      if (this.handlingPageScroll || this.scrollingToGroup) {
        return;
      }

      // Run on next frame
      window.requestAnimationFrame(() => {
        // Select first panel by default
        let currentPanelConfig = this.groupPanels[0];

        // Loop through mounted panels
        for (let i = 0; this.$refs.panels && i < this.$refs.panels.length; i++) {
          currentPanelConfig = this.groupPanels[i];

          // Break loop on last panel because there is no panel next
          if (i === this.$refs.panels.length - 1) {
            break;
          }

          // Check if the top of the next panel is bellow the top of the page
          const nextPanelEl = this.$refs.panels[i + 1].$el;
          const nextPanelRect = nextPanelEl.getBoundingClientRect();
          const nextPanelTop = nextPanelRect.top;
          const scrollGutter = 20;

          if ((nextPanelTop - scrollGutter) >= this.getPageOffset()) {
            // Break the loop. Last panel selected should be the current panel
            break;
          }
        }

        // If current panel key is different than state, update state
        if (currentPanelConfig && currentPanelConfig.key !== this.groupNavKey) {
          this.$store.dispatch('crud/setGroupNavKey', {
            key: currentPanelConfig.key,
            scroll: false, // Pevent automatic scrolling
          });
        }

        // Handling done, ready for next frame
        this.handlingPageScroll = false;
      });

      // Prevent further calls
      this.handlingPageScroll = true;
    },
    /**
     * Scroll to group panel
     * Looks for the panel element with the corresponding key and scrolls to it
     * @param {string} key - The key of the panel to scroll to
     */
    scrollToGroup (key) {
      const targetPanelIndex = this.groupPanels.findIndex(group => group.key === key);

      if (targetPanelIndex < 0) {
        return;
      }

      const targetPanelEl = this.$refs.panels[targetPanelIndex].$el;
      const targetPanelRect = targetPanelEl.getBoundingClientRect();
      const scrollGutter = 12;

      // Set scrollingToGroup to true during scroll
      this.scrollingToGroup = true;
      smoothScrollTo(
        // Remove page offset to account for header
        (targetPanelRect.top - scrollGutter) + window.pageYOffset - this.getPageOffset(),
        // Set scrollingToGroup to false after scroll
        () => this.scrollingToGroup = false
      );
    },
    /**
     * Update treeview scroll position.
     * Scrolls the active tree nodes within view.
     */
    updateTreeviewScrollPosition () {
      this.$nextTick(() => {
        if (this.$refs.treeview) {
          // Find the first expanded node
          let targetEl = this.$refs.treeview.$el.querySelector('.v-treeview-node[aria-expanded=true]');

          // If none are expanded, find the first active node
          if (!targetEl) {
            targetEl = this.$refs.treeview.$el.querySelector('.v-treeview-node--active');
          }

          // If we have a target, scroll to it
          if (targetEl) {
            this.$refs.treeviewWrapper.scrollTop = targetEl.offsetTop;
          }
        }
      });
    },
    setFieldHasErrors (field, hasError) {
      if (hasError && !this.fieldsWithErrors.includes(field)) {
        this.fieldsWithErrors.push(field);
      } else if (!hasError && this.fieldsWithErrors.includes(field)) {
        const index = this.fieldsWithErrors.indexOf(field);
        this.fieldsWithErrors.splice(index, 1);
      }
    },
    handleUpdateData ({ key, value }) {
      this.$set(this.workingData, key, value);
      this.$set(this.formData, key, value);
      if (this.formHasUnsavedChanges === false) {
        this.setFormHasUnsavedChanges(true);
      }
    },
    handleRankingUpdate () {
      this.handleSave();
    },
    handleStatus (value) {
      this.formData[this.typeConfig.itemStatus] = value;
      if (this.formHasUnsavedChanges === false) {
        this.setFormHasUnsavedChanges(true);
      }
    },
    /**
     * Trigger GQL mutation to duplicate item, redirect to new item page.
     *
     * @returns {void}
     */
    async duplicateItem () {
      this.isDuplicating = true;
      const { idSingular, editId } = this;

      try {
        const response = await this.executeDuplicateMutation({
          idSingular,
          fromId: editId,
          mutation: 'duplicate',
        });
        const messages = get(response, 'data.messages',
          [{ type: 'success', message: 'dialogs.duplicate_success' }]);

        setTimeout(() => this.$store.$apollo.defaultClient.resetStore(), 250);
        this.$reportSuccess(messages);
      } catch (err) {
        this.$reportError({ message: `${err.message}` });
      }

      this.isDuplicating = false;
    },
    customDialogCloseAction () {
      this.hideUnsavedWarning();
    },
    confirmExitWithoutSaving (to) {
      this.customDialogTitle = this.$t('dialogs.exit_without_saving_title');
      this.customDialogSubtitle = this.$t('dialogs.exit_without_saving_subtitle');
      this.customDialogAction = () => this.handleExitWithoutSaving(to);
      this.customDialogShowAction = true;
      this.customDialogOpen = true;
    },
    handleExitWithoutSaving (to) {
      this.hasConfirmExitWithoutSaving = true;
      this.$router.push(to);
      this.hideUnsavedWarning();
    },
    handleSave () {
      const form = Array.isArray(this.$refs['form-edit']) ? this.$refs['form-edit'][0] : this.$refs['form-edit'];

      if (form.validate()) {
        if (this.fieldsWithErrors && this.fieldsWithErrors.length > 0) {
          this.customDialogTitle = this.$t('errors.field_error_title');
          this.customDialogSubtitle = this.$t('errors.field_error_subtitle', { element: this.$t(this.fieldsWithErrors[0])});
          this.customDialogAction = () => this.customDialogOpen = false;
          this.customDialogShowAction = false;
          this.customDialogOpen = true;
        } else {
          this.save();
        }
      }
    },
    async save () {
      const { formData, editId } = this;
      this.loadingSave = true;

      try {
        await this.executePatchMutation({
          mutationName: this.getPatchMutationName(this.typeConfig.idSingular),
          targetId: editId,
          fields: this.fields
        }, formData);

        this.formData = {};
        this.setFormHasUnsavedChanges(false);

        this.$reportSuccess({ message: this.$t('dialogs.update_success') });
      } catch (error) {
        console.error(error);

        this.$reportError({ message: `${error.message}` });
      }

      this.loadingSave = false;
    },
  },
};
</script>

<style lang="scss">
// Expansion panel overrides
.v-expansion-panel {
  .v-data-table,
  .tree-table .table-header,
  .v-treeview {
    margin-right: -$spacer;
    margin-left: -$spacer;
  }
}

.v-expansion-panel-header,
.v-expansion-panel-content__wrap {
  padding-right: $spacer;
  padding-left: $spacer;
}

// wrap file in container, icon to remove file
.file-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.file-field {
  display: block;
  max-width: 100%;
  max-height: 150px;
  width: auto;
  height: auto;
}

.file-container .file-remove {
  position: absolute;
  top: 0;
  right: 0;
}

.file__placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: $spacer * 2;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.treeview-column {
  position: relative;
}

.treeview-wrapper {
  border-radius: 4px;
  background-color: #fff;
  position: sticky;
  top: 120px;
  left: 0;
  overflow: auto;
  max-height: calc(100vh - 144px);
}

.content-bar-center__items {
  display: flex;

  @media (min-width: $bp-sm) {
    margin-left: $spacer/2;
  }
}
</style>
