<template>
  <!-- Mode select dropdown -->
  <v-autocomplete
    :value="defaultValue"
    :items="items"
    class="mode-select"
    item-value="version"
    hide-details
    outlined
    dense
    @input="emitInput"
    @keydown.enter.stop
  >
    <!-- Selected item slot -->
    <template v-slot:selection="{ item }">
      <div class="v-select__selection v-select__selection--comma">
        <v-chip
          :color="setChipColor(item)"
          label
          x-small
          outlined
          class="px-1"
          v-text="`V${item.version}`"
        />
        {{ $t(`generic.${item.mode}`) }} - <strong>{{ item.date_created }}</strong>
      </div>
    </template>
    <!-- Options list style slot -->
    <template v-slot:item="{ item }">
      <div class="v-list-item__content">
        <div class="v-list-item__title">
          <span>
            <!-- Tag -->
            <v-chip
              :color="setChipColor(item)"
              label
              x-small
              outlined
              class="px-1"
              v-text="`V${item.version}`"
            />
            <!-- Label (mode name to left) -->
            &nbsp;{{ $t(`generic.${item.mode}`) }}&nbsp;
          </span>
          <span>
            <!-- Label (date created to right) -->
            <strong>{{ item.date_created }}</strong>
          </span>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ModeSelect',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  // Always return first value on render
  data () {
    return {
      defaultValue: this.items[0],
    };
  },
  // Init default value on created & send to parent
  created () {
    this.emitInput(this.defaultValue.version);
  },
  methods: {
    /**
     * Sets current chip color according to mode
     * grey -> archived, orange -> draft, green -> live
     *
     * @returns {String}
     */
    setChipColor (item) {
      switch (item.mode) {
        case 'live':
          return 'success';
        case 'draft':
          return 'warning';
        default:
          return 'grey';
      }
    },
    /**
     * Send input to parent component for mode + value treatment
     *
     * @param {Object} version - The single version of the currently selected item
     * @returns {Void}
     */
    emitInput (version) {
      this.$emit('input', this.items.find(item => item.version === version));
    },
  },
};
</script>

<style lang="scss" scoped>
// Select input overrides
.mode-select {
  @include rem(max-width, 155px);

  ::v-deep .v-input__slot {
    padding: $spacer/5 !important;

    @include rem(height, 28px, !important);
    @include rem(min-height, 28px, !important);
  }

  ::v-deep .v-input__append-inner {
    margin-top: 0 !important;
  }

  @media (min-width: $bp-sm) {
    @include rem(max-width, 250px);
  }
}

// Dropdown item title
.v-list-item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
